import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

// Data
import TXPClient from '../img/txp-klient.png';
import Logo from '../img/logo-new.png';

import Footer from '../components/Footer';

// Components

function OfferPrice() {
    return (
        <>
            <div className='py-5'>
                <div className='position-relative zindex-2 top-section pt-3 pt-lg-5'>
                    <Container>
                        <Row className='gy-6'>
                            <Col lg={{ span: 5, offset: 1 }}>
                                <div>
                                    <div className='logo mb-4 mb-lg-5'>
                                        <img src={Logo} alt="" />
                                    </div>
                                    <h2 className='mb-4'>Wiesz za ile jedziesz!</h2>
                                    <h2 className='h3 mb-4 mb-lg-5'>Usługi Taksówkarskie</h2>
                                    <h3 className="h4 mb-4">
                                        Strefa nr 2 (powiatowa)
                                    </h3>
                                    <p className='text-mobile-base text-lg fw-bold'>
                                        Taryfa dzienna
                                    </p>
                                    <p className='text-mobile-base text-lg mb-4'>
                                        Opłata początkowa 12 zł w tym 1 km <br />następnie 4,50 zł/km
                                    </p>
                                    <p className='text-mobile-base text-lg fw-bold'>
                                        Taryfa nocna i święta
                                    </p>
                                    <p className='text-mobile-base text-lg mb-4 mb-lg-5'>
                                        Opłata początkowa 12 zł w tym 1 km <br />następnie 5,50 zł/km
                                    </p>
                                    <h3 className="h4 mb-4">
                                        Strefa nr 1 (aglomeracyjna)
                                    </h3>
                                    <p className='text-mobile-base text-lg fw-bold'>
                                        Minimalna cena za kurs 30 zł
                                    </p>
                                    <p className='text-mobile-base text-lg fw-bold'>
                                        Taryfa dzienna
                                    </p>
                                    <p className='text-mobile-base text-lg mb-4'>
                                        Opłata początkowa 12 zł w tym 1 km <br />następnie 6,00 zł/km
                                    </p>
                                    <p className='text-mobile-base text-lg fw-bold'>
                                        Taryfa nocna i święta
                                    </p>
                                    <p className='text-mobile-base text-lg mb-4 mb-lg-5'>
                                        Opłata początkowa 12 zł w tym 1 km <br />następnie 7,00 zł/km <br /> Godzina postoju 60 zł
                                    </p>
                                    <h3 className="h4 mb-4">
                                        Przewóz Osób
                                    </h3>
                                    <p className='text-mobile-base text-lg fw-bold'>
                                        Od 3 zł/km przykładowe ceny kursów:
                                    </p>
                                    <p className='text-mobile-base text-lg'>
                                        Wrocław Lotnisko od 350 zł
                                    </p>
                                    <p className='text-mobile-base text-lg'>
                                        Warszawa Okęcie od 850 zł
                                    </p>
                                    <p className='text-mobile-base text-lg'>
                                        Ostrów Wlkp. od 200 zł
                                    </p>
                                    <p className='text-mobile-base text-lg'>
                                        Ostrzeszów od 90 zł
                                    </p>
                                    <p className='text-mobile-base text-lg'>
                                        Wieruszów od 60 zł
                                    </p>
                                    <p className='text-mobile-base text-lg mb-4 mb-lg-5'>
                                        Syców od 100 zł
                                    </p>
                                    <p className='text-lg mb-4 mb-lg-5'><strong>Wystawiamy paragony i faktur VAT</strong> <br />paragony są wystawiane w postaci elektronicznej</p>
                                </div>
                            </Col>
                            <Col lg={{ span: 4, offset: 2 }}>
                                <div className='h-100'>
                                    <div className='text-center position-sticky'>
                                        <img src={TXPClient} width={323} height={600} loading='lazy' alt='TXP Aplikacja Klient' />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default OfferPrice;