import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';


// Icons
import Icon from '@mdi/react';
import { mdiCheckBold } from '@mdi/js';

// Data
import TXPClient from '../img/txp-klient.png';
import Logo from '../img/logo-new.png';

import Footer from '../components/Footer';

// Components

function Offer() {
    return (
        <>
            <div className='py-5'>
                <div className='position-relative zindex-2 top-section pt-3 pt-lg-5'>
                    <Container>
                        <Row className='gy-6'>
                            <Col lg={{ span: 5, offset: 1 }}>
                                <div>
                                    <div className='logo mb-4 mb-lg-5'>
                                        <img src={Logo} alt="" />
                                    </div>
                                    <h2 className='mb-5'>Twój przewoźnik powiatowy</h2>
                                    <h2 className='h3 mb-4 '>Przewozy taksówkarskie  w mieście i powiecie Kępińskim</h2>
                                    <ul className='list-unstyled mb-5'>
                                        <li className='d-flex align-items-center text-mobile-base text-lg mb-3'>
                                            <span className='d-flex icon-check me-3'>
                                                <Icon path={mdiCheckBold} size={1} />
                                            </span>
                                            <strong>Zamawianie kursów przez telefon lub aplikację</strong>
                                        </li>
                                        <li className='d-flex align-items-center text-mobile-base text-lg mb-3'>
                                            <span className='d-flex icon-check me-3'>
                                                <Icon path={mdiCheckBold} size={1} />
                                            </span>
                                            <strong>Karty bezgotówkowe dla firm i osób prywatnych – pre paid</strong>
                                        </li>
                                        <li className='d-flex align-items-center text-mobile-base text-lg mb-3'>
                                            <span className='d-flex icon-check me-3'>
                                                <Icon path={mdiCheckBold} size={1} />
                                            </span>
                                            <strong>Karty lojalnościowe system punktów zamiennych na darmowe kursy</strong>
                                        </li>
                                        <li className='d-flex align-items-center text-mobile-base text-lg mb-3'>
                                            <span className='d-flex icon-check me-3'>
                                                <Icon path={mdiCheckBold} size={1} />
                                            </span>
                                            <strong>Karty lojalnościowe rabaty  5 – 10 -15 %</strong>
                                        </li>
                                        <li className='d-flex align-items-center text-mobile-base text-lg mb-3'>
                                            <span className='d-flex icon-check me-3'>
                                                <Icon path={mdiCheckBold} size={1} />
                                            </span>
                                            <strong>Wystawiamy paragony i faktury VAT</strong>
                                        </li>
                                    </ul>
                                    <h2 className='h3 mb-4'>Okazjonalny przewóz osób w Polsce</h2>
                                    <ul className='text-mobile-base text-lg mb-0'>
                                        <li>imprezy okolicznościowe</li>
                                        <li>wyjazdy do klubów</li>
                                        <li>transfery na lotnisko</li>
                                        <li>wyjazdy do sanatorium</li>
                                        <li>wyjazdy turystyczne</li>
                                        <li>SUV-y 4 osoby i Busy 7 osób</li>
                                        <li>Licencja transportowa - Ceny do negocjacji</li>
                                    </ul>
                                </div>
                            </Col>
                            <Col lg={{ span: 4, offset: 2 }}>
                                <div className='h-100'>
                                    <div className='text-center position-sticky'>
                                        <img src={TXPClient} width={323} height={600} loading='lazy' alt='TXP Aplikacja Klient' />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Offer;