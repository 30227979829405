import React from 'react';

// Components
import { Container, Row, Col, Button } from 'react-bootstrap';
import TXPKlient from '../img/txp-klient.png';
import Logo from '../img/logo-new.png';

import Footer from '../components/Footer';

function Home() {
    return (
        <>
            <div className='py-5'>
                <div className='position-relative zindex-2 top-section pt-3 pt-lg-5'>
                    <Container>
                        <Row className='align-items-lg-center gy-6'>
                            <Col lg={{ span: 5, offset: 1 }}>
                                <div className='text-center text-lg-start'>
                                    <div className='logo ms-0 mb-4'>
                                        <img src={Logo} alt="" />
                                    </div>
                                    <div className="text-center">
                                        <h2 className='mb-3'>Taxi Powiatowe</h2>
                                        <h3 className='h5 fw-bold mb-4'>Przewozy taksówkarskie i osobowe w mieście i powiecie Kępińskim</h3>
                                        <p className='text-base-mobile text-lg mb-4'>Przejazdy są prowadzone przez kierowców z lokalnej społeczności.</p>
                                        <p className='text-base-mobile text-lg fw-bold mb-4 mb-lg-5'>Szybka i bezpieczna taksówka – wszyscy nasi kierowcy posiadają licencję, niezbędne badania psychotechnicznie i zaświadczenia o niekaralności</p>
                                        <div className='d-flex flex-column flex-md-row align-items-center justify-content-center gap-3'>
                                            <Button href='/uslugi' variant='dark' className='btn-mobile-100 px-lg-5 shadow'>Usługi</Button>
                                            <Button href='/cennik' variant='light' className='btn-mobile-100 px-lg-5 shadow'>Cennik</Button>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={{ span: 4, offset: 2 }}>
                                <div className='text-center'>
                                    <img src={TXPKlient} width={323} height={600} loading='lazy' alt='TXP Aplikacja Klient' />
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Home;